import { REPORT_GAME_TYPES } from "./reports.constants";

export const GAME_TYPE = {
	FOOTBALL_SINGLE_MATCH: 1,
	ENGLISH_LEAGUE: 2,
	HORSE_RACING: 4,
	GREYHOUNDS_RACE: 8,
	AFRICAN_CUP: 16,
	KENO: 32,
	WORLD_CUP: 64,
	EUROPEAN_CUP: 128,
	CHAMPIONS_LEAGUE: 256,
	COPA_LIBERTADORES: 512,
	HORSE_STEEPLECHASING: 1024,
	PENALTY_SHOOTOUT: 2048,
	// TURKISH_LEAGUE: 4096
	LUCKY_SIX: 8192,
	SPIN_TO_WIN: 16384
};

export const GAME_TYPE_TEXT_KEYS = {
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: "virtualFootball",
	[GAME_TYPE.HORSE_RACING]: "horseRacing",
	[GAME_TYPE.GREYHOUNDS_RACE]: "greyhounds",
	[GAME_TYPE.ENGLISH_LEAGUE]: "englishLeague",
	[GAME_TYPE.KENO]: "keno",
	[GAME_TYPE.AFRICAN_CUP]: "africanCup",
	[GAME_TYPE.WORLD_CUP]: "footballWorldCup",
	[GAME_TYPE.EUROPEAN_CUP]: "footballEuropeanCup",
	[GAME_TYPE.CHAMPIONS_LEAGUE]: "footballChampionsLeague",
	[GAME_TYPE.COPA_LIBERTADORES]: "footballCopaLibertadores",
	[GAME_TYPE.HORSE_STEEPLECHASING]: "steeplechase",
	[GAME_TYPE.LUCKY_SIX]: "luckySix",
	[GAME_TYPE.SPIN_TO_WIN]: "spin2Win",
	[GAME_TYPE.PENALTY_SHOOTOUT]: "penaltyShootout"
	// [GAME_TYPE.TURKISH_LEAGUE]: "turkishLeague",
};

export const GAME_TYPE_TEXTS_BY_NAME = {
	footballSingleMatch: "common.virtualFootball",
	horseRaceFlat: "common.horseRacing",
	greyhoundRace: "common.greyhounds",
	footballEnglandLeague: "common.englishLeague",
	keno: "common.keno",
	footballAfricanCup: "common.africanCup",
	footballWorldCup: "common.footballWorldCup",
	footballEuropeanCup: "common.footballEuropeanCup",
	footballChampionsLeague: "common.footballChampionsLeague",
	footballCopaLibertadores: "common.footballCopaLibertadores",
	penalty: "common.penaltyShootout",
	horseRaceSteeplechasing: "common.steeplechase",
	spin2Win: "common.spin2Win",
	luckySix: "common.luckySix",
	[REPORT_GAME_TYPES.TOTAL]: "cashier.total",
	[REPORT_GAME_TYPES.MIXED_MULTI]: "cashier.mixedMulti"
};

export const GAME_TYPE_ICONS = {
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: "ic_football",
	[GAME_TYPE.HORSE_RACING]: "ic_horseRacing",
	[GAME_TYPE.GREYHOUNDS_RACE]: "ic_greyhounds",
	[GAME_TYPE.ENGLISH_LEAGUE]: "ic_league_trophy",
	[GAME_TYPE.KENO]: "ic_keno",
	[GAME_TYPE.AFRICAN_CUP]: "ic_africanCup",
	[GAME_TYPE.WORLD_CUP]: "ic_worldCup",
	[GAME_TYPE.EUROPEAN_CUP]: "ic_europeanCup",
	[GAME_TYPE.CHAMPIONS_LEAGUE]: "ic_championsLeague",
	[GAME_TYPE.COPA_LIBERTADORES]: "ic_copaLibertadores",
	[GAME_TYPE.HORSE_STEEPLECHASING]: "ic_horseRaceSteeplechasing",
	[GAME_TYPE.PENALTY_SHOOTOUT]: "ic_penaltyShootout",
	[GAME_TYPE.SPIN_TO_WIN]: "ic_spin2Win",
	[GAME_TYPE.LUCKY_SIX]: "ic_luckySix"
	// [GAME_TYPE.TURKISH_LEAGUE]: "ic_turkishLeague",
};

export const GAME_EVENT_TYPE = {
	EVENT: 1,
	WEEK: 2,
	LEAGUE: 3
};

export const GAME_STATUSES = {
	NEW: 1,
	CLOSE_FOR_BETTING: 2,
	STARTED: 4,
	FINISHED: 8,
	CANCELED: 16,
	PREAMBLE_STARTED: 32
};

export const GAME_ACTIVITY_STATE = {
	ACTIVE: 1,
	CLOSE_FOR_BETTING: 2,
	INACTIVE: 4
};

export const GAME_STREAM_CONFIGURATION_TYPE = {
	WOWZA: 1,
	NANOCOSMOS: 2,
	FLUSSONIC: 4,
	OVEN_PLAYER: 8
};

export const KENO_BALLS_COUNT = 80;
export const KENO_WINNER_BALLS_COUNT = 20;
export const KENO_BALLS_QTY_IN_ROW = 10;
export const KENO_BALLS_QTY_IN_COLUMN = 8;
export const KENO_FORMAT = {
	KENO_6: 1,
	KENO_8: 2,
	KENO_10: 4,
	KENO_12: 8
};

export const KENO_FORMAT_BALLS_QTY = {
	[KENO_FORMAT.KENO_6]: 6,
	[KENO_FORMAT.KENO_8]: 8,
	[KENO_FORMAT.KENO_10]: 10,
	[KENO_FORMAT.KENO_12]: 12
};

export const HORSE_RACE_FORMAT = {
	HORSE_6: 1,
	HORSE_8: 2,
	HORSE_10: 4,
	HORSE_12: 8
};

export const GREYHOUNDS_RACE_FORMAT = {
	GREYHOUND_6: 1,
	GREYHOUND_8: 2
};

export const CUP_GAMES_FINAL_ROUND_NUMBER = {
	[GAME_TYPE.AFRICAN_CUP]: 5,
	[GAME_TYPE.WORLD_CUP]: 5,
	[GAME_TYPE.EUROPEAN_CUP]: 5,
	[GAME_TYPE.CHAMPIONS_LEAGUE]: 7,
	[GAME_TYPE.COPA_LIBERTADORES]: 7
};

export const RESULTS = {
	WIN: 1,
	LOSE: 2,
	DRAW: 3
};
export const PENALTY_SCENE_INFO = {
	GOAL_TOP_LEFT_SPECTER: 1 << 0 /* 1 */,
	GOAL_TOP_CENTRE_SPECTER: 1 << 1 /* 2 */,
	GOAL_TOP_RIGHT_SPECTER: 1 << 2 /* 4 */,
	GOAL_CENTRE_LEFT_SPECTER: 1 << 3 /* 8 */,
	GOAL_CENTRE_CENTRE_SPECTER: 1 << 4 /* 16 */,
	GOAL_CENTRE_RIGHT_SPECTER: 1 << 5 /* 32 */,
	GOAL_BOTTOM_LEFT_SPECTER: 1 << 6 /* 64 */,
	GOAL_BOTTOM_CENTRE_SPECTER: 1 << 7 /* 128 */,
	GOAL_BOTTOM_RIGHT_SPECTER: 1 << 8 /* 256 */,
	NOGOAL_MISS_SHOOT: 1 << 9 /* 512 */,
	NOGOAL_SAVE: 1 << 10 /* 1024 */,
	NOGOAL_WOODWORK: 1 << 11 /* 2048 */
};

export const PENALTY_SCENE_INFO_NAMES = {
	[PENALTY_SCENE_INFO.GOAL_TOP_LEFT_SPECTER]: "TopLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_TOP_CENTRE_SPECTER]: "TopCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_TOP_RIGHT_SPECTER]: "TopRightSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_LEFT_SPECTER]: "CentreLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_CENTRE_SPECTER]: "CentreCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_RIGHT_SPECTER]: "CentreRightSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_LEFT_SPECTER]: "BottomLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_CENTRE_SPECTER]: "BottomCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_RIGHT_SPECTER]: "BottomRightSpecter",
	[PENALTY_SCENE_INFO.NOGOAL_MISS_SHOOT]: "MissShoot",
	[PENALTY_SCENE_INFO.NOGOAL_SAVE]: "Save",
	[PENALTY_SCENE_INFO.NOGOAL_WOODWORK]: "Woodwork"
};

export const RED_NUMBERS = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
