import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import BlockedMessage from "components/ui/blockedMessage";
import Loader from "components/ui/loader";
import Games from "../../games";
import Tabs from "./tabs";
import Markets from "./markets";
import SeasonMarkets from "./seasonMarkets";
import UnavailableGameLayer from "./seasonMarkets/unavailableGameLayer";

import useUnavailableGameLayer from "hooks/useUnavailableGameLayer";

import { buildPathToStaticFolderOfCDN, isSeasonGame } from "utils/common";

import { GAME_TYPE, GAME_ACTIVITY_STATE } from "constants/game.constants";
import eventType from "types/event.type";

import { clearForecast, clearKenoBalls } from "store/actions/betslip/betslip.actions";

/** Main page Component */
const MainPage = ({ current, matches, isLoading, clearForecast, clearKenoBalls, currentGameType, games, liveAndUpcomings, isLiveAndUpcomingsLoading }) => {
	const [activeTab, setActiveTab] = useState("main");
	const [data, setData] = useState([]);
	const [tabNameOfCalculated, setTabNameOfCalculated] = useState(activeTab);

	const { gameIsNotAvailable, countdownValue } = useUnavailableGameLayer();

	const game = games.find((game) => game.type === currentGameType);

	const isCurrentGameDeactivated = (game?.state ?? GAME_ACTIVITY_STATE.ACTIVE) === GAME_ACTIVITY_STATE.INACTIVE;
	const isBlockedMessageVisible = isCurrentGameDeactivated && !isLiveAndUpcomingsLoading && liveAndUpcomings.length === 0;

	/** Make markets data, to show */
	useEffect(() => {
		if (current) {
			const currentMatch = matches.find((m) => m.id === current);
			if (!isSeasonGame(currentGameType)) {
				const d = currentMatch?.markets?.[activeTab] ?? [];
				setData(d);
				setTabNameOfCalculated(activeTab);
			}
		} else {
			setData([]);
			setTabNameOfCalculated(activeTab);
		}

		clearForecast();
		clearKenoBalls();
	}, [activeTab, isLoading, current]);

	/** On event change set main as active tab */
	useEffect(() => {
		setActiveTab("main");
	}, [current]);

	/** Function to get event info for current event, from loaded events
	 * @function
	 * @returns {object} - event info
	 * @memberOf MainContent
	 */
	const getEventInfo = () => {
		const match = matches.find((m) => m.id === current);

		const parentEvent = match?.event ?? {};

		if (isSeasonGame(currentGameType)) {
			return {
				parentEvent,
				eventInfos: parentEvent?.events ?? []
			};
		}

		return {
			gameData: parentEvent.gameData ?? {},
			id: parentEvent.id ?? null,
			startTime: parentEvent.startTime ?? null,
			status: parentEvent.status ?? null,
			type: parentEvent.type ?? null,
			gameType: parentEvent.gameType ?? null,
			orderNumber: parentEvent.orderNumber ?? null
		};
	};

	return (
		<div className="vs--markets vs--flex vs--flex-col" data-game={currentGameType}>
			{
				isLoading || (!isSeasonGame(currentGameType) && tabNameOfCalculated !== activeTab)
					? (<Loader full={true} />)
					: (
						<Fragment>
							{
								![GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX].includes(currentGameType) && !isBlockedMessageVisible
									? (
										<Tabs
											onTabClick={setActiveTab}
											activeTab={activeTab}
										/>
									)
									: null
							}
							{
								isBlockedMessageVisible
									? (
										<BlockedMessage
											className="vs--pt-12"
											imageSrc={buildPathToStaticFolderOfCDN("cashier-images/plug.svg")}
											title='common.gameIsDeactivated'
											message='common.gameIsDeactivatedMessage'
										/>
									)
									: (
										<div className="vs--markets-main vs--flex-equal">
											{
												isSeasonGame(currentGameType)
													? (
														<SeasonMarkets
															activeTab={activeTab}
															{...getEventInfo()}
														/>
													)
													: (
														<Markets
															activeTab={activeTab}
															eventInfo={getEventInfo()}
															markets={data}
														/>
													)
											}
										</div>
									)
							}
							{
								gameIsNotAvailable
									? (
										<UnavailableGameLayer countdownValue={countdownValue} />
									)
									: null
							}
						</Fragment>
					)
			}
			<Games />
		</div>
	);
};

/** MainPage propTypes
 * PropTypes
 */
MainPage.propTypes = {
	/** Redux state property, all loaded matches */
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			event: eventType
		})
	),
	/** Redux state property, current match id */
	current: PropTypes.number,
	/** Redux state property, is true when match details is loading */
	isLoading: PropTypes.bool,
	/** Redux action to clear forecast */
	clearForecast: PropTypes.func,
	/** Redux action to clear keno balls */
	clearKenoBalls: PropTypes.func,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, the array of live and upcomings events */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Redux state property, live and upcoming matches loading */
	isLiveAndUpcomingsLoading: PropTypes.bool,
	/** Redux state property, Games */
	games: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		matches: state.game.matches.data,
		current: state.game.current,
		isLoading: state.game.matches.loading || state.game.liveAndUpcomings.loading,
		currentGameType: state.game.currentGameType,
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		isLiveAndUpcomingsLoading: state.game.liveAndUpcomings.loading,
		games: state.auth.session.games
	};
};

const mapDispatchToProps = (dispatch) => ({
	clearForecast: () => {
		dispatch(clearForecast());
	},
	clearKenoBalls: () => {
		dispatch(clearKenoBalls());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
