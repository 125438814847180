const LuckySixMarkets = () => {
	
	const colors = [
		'red',
		'blue',
		'green',
		'yellow',
		'orange',
		'violet',
		'purple',
		'black'
	];

	const color = 'red';

	return (
		<div className="vs--lucky-six vs--flex vs--align-center vs--justify-center">

			<div className="vs--lucky-six-container vs--flex vs--justify-between vs--align-center">

				<div className="vs--lucky-six-container-default-market vs--flex vs--flex-col vs--align-center">

					<div className="vs--lucky-six-container-default-market-block vs--flex vs--flex-col">

						<div className="vs--lucky-six-container-default-market-block-colors vs--flex vs--align-center vs--justify-between">
						{colors.map((color, index) => (
								<div
									key={index}
									className="vs--lucky-six-select-color vs--flex vs--align-center vs--justify-center"
								>
									<div
										className={`vs--lucky-six-select-color-button vs--lucky-six-select-color-button-${color}`}
									>
										<div className="vs--lucky-six-select-color-button-inner" />
									</div>
								</div>
							))}
						</div>
					
						<div className="vs--lucky-six-container-default-market-block-numbers vs--flex vs--flex-wrap">
							{Array.from({ length: 48 }, (_, i) => (
									<div
										key={i}
										className="vs--lucky-six-ball vs--lucky-six-ball-yellow vs--flex vs--justify-center vs--align-center" // vs--lucky-six-ball-disabled, vs--lucky-six-ball-active
										>
										<span className="vs--lucky-six-ball-text">{i + 1}</span>
									</div>
								))}

						</div>

					</div>
					
					<div className="vs--lucky-six-container-default-market-random vs--markets-list-item-content-odd-item vs--flex vs--align-center">
						<div className="vs--markets-list-item-content-odd-item-title">
							<span className="vs--font-small">Random</span>
						</div>
					</div>		

				</div>
				
				<div className="vs--lucky-six-container-other-market vs--flex">
					
					<div className="vs--lucky-six-container-other-market-block vs--flex vs--flex-col">

						<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
								<span className="vs--font-exstrasmall vs--font-medium">FIRST BALL COLOR</span>

								<div className="vs--lucky-six-container-other-market-block-content-colors vs--flex vs--flex-wrap vs--justify-center vs--align-center">
								{colors.map((color, index) => (
									<div
										key={index}
										className="vs--lucky-six-select-color vs--flex vs--align-center vs--justify-center"
									>
										<div
											className={`vs--lucky-six-select-color-button vs--lucky-six-select-color-button-${color}`}
										>
											<div className="vs--lucky-six-select-color-button-inner" />
										</div>
									</div>
								))}
								</div>
						</div>

						<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
							<span className="vs--font-exstrasmall vs--font-medium">FIRST NUMBER</span>

							<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

							</div>

						</div>

						<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
							<span className="vs--font-exstrasmall vs--font-medium">PRE NUMBERS SUM TOTAL</span>

							<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

							</div>

						</div>

					</div>

					<div className="vs--lucky-six-container-other-market-block vs--flex vs--flex-col">

						<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
								<span className="vs--font-exstrasmall vs--font-medium">LAST BALL COLOR</span>

								<div className="vs--lucky-six-container-other-market-block-content-colors vs--flex vs--flex-wrap vs--justify-center vs--align-center">
									{colors.map((color, index) => (
									<div
										key={index}
										className="vs--lucky-six-select-color vs--flex vs--align-center vs--justify-center"
									>
										<div
											className={`vs--lucky-six-select-color-button vs--lucky-six-select-color-button-${color}`}
										>
											<div className="vs--lucky-six-select-color-button-inner" />
										</div>
									</div>
								))}
								</div>
						</div>

						<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
							<span className="vs--font-exstrasmall vs--font-medium">LAST NUMBER</span>

							<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

							</div>

						</div>

						<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
							<span className="vs--font-exstrasmall vs--font-medium">MOSTLY DRAWN NUMBERS</span>

							<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Over (24.5)</span>
									</div>
								</div>

								<div className="vs--markets-list-item-content-odd-item vs--flex vs--align-center">
									<div className="vs--markets-list-item-content-odd-item-title">
										<span className="vs--font-small">Odd</span>
									</div>
								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</div>
	)
};

export default LuckySixMarkets;
