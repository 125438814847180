const ResultOfLuckySixComponent = ({ event }) => {
	console.log(event)
	return (
		<div>
			456
		</div>
	);
};

export default ResultOfLuckySixComponent;
